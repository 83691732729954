<div class="doc-request-slide" [class.notification-slide-collapsed]="!isShowPanel">
  <div class="doc-request-header d-flex justify-content-between align-items-center">
    <h3>{{title}}</h3>
      <a href="javacript:void(0)" (click)="requestToClosePanel()">
        <img src="assets/images/icons/icon-close-2.svg" />
      </a>
  </div>

  <div class="doc-request-body clearfix">

    @if(isDetailsLoading){
      <app-main-loader></app-main-loader>
    }@else if(details) {

      <app-discussion-panel-header
        [title]="details.title"
        [description]="details.description"
        [count]="details.count"
        [user]="details.requested_by"
        [status]="details.request_status"
        [date]="details.requested_at">
      </app-discussion-panel-header>

      <app-discussion-panel-body
        [submitTitle]="submitTitle"
        [submitDescription]="submitDescription"
        [isLoading]="isCommentLoading"
        [status]="details.request_status?.type"
        [comments]="comments"
        (submitRequest)="requestToSubmitMainItem()"
        (updateCommentStatusRequest)="updateCommentStatus($event)">
      </app-discussion-panel-body>
    @if(isdisscussionPanelSubmitEnabled){
      <app-discussion-panel-submit
        [isDocumentFieldEnabled]="isDocumentFieldEnabled"
        [isDiscussionStatusFieldEnable]="isDiscussionStatusFieldEnable"
        [discussionStatuses]="discussionStatuses"
        (saveCommentRequest)="requestToSaveComment($event)">
      </app-discussion-panel-submit>
    }
    }
  </div>
</div>
